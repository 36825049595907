import React, { Component } from 'react';
import logo from './logo.svg';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './App.css';
import Lottie from "lottie-react";
import signatureAnimation1 from "./lottie1.json";
import signatureAnimation2 from "./lottie2.json";

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = { error: null, isLoaded: false, items: [] };
    this.state = {
      videoSound: true,
      textb0: "Wir denken uns",
      textb1: "in alles rein. Nur nicht",
      textb2: "in Schubladen."
    };

    this.disableVideoSound = this.disableVideoSound.bind(this);
    this.changeTextB = this.changeTextB.bind(this);
    this.changeTextBa = this.changeTextBa.bind(this);
    this.checkScrollFromTop = this.checkScrollFromTop.bind(this);

  }

  changeTextB () {
    let t0 = "Träumen ist";
    let t1 = "nichts für Träumer.";
    let t2 = "Sondern für Macher.";
    this.setState ({ textb0: t0, textb1: t1, textb2: t2 });
    setTimeout(() => { this.changeTextBa (); }, 5000);
  }

  changeTextBa () {
    let t0 = "Wir denken uns";
    let t1 = "in alles rein. Nur nicht";
    let t2 = "in Schubladen.";
    this.setState ({ textb0: t0, textb1: t1, textb2: t2 });
    setTimeout(() => { this.changeTextB (); }, 5000);
  }

  disableVideoSound () {

  }

  checkScrollFromTop () {
    const content = document.getElementById("ck");
    document.addEventListener("scroll", (e) => {

      var scrolled = document.scrollingElement.scrollTop;
      var position = content.offsetTop;

      if(scrolled > position + 10){
        content.classList.add('displayBlockCK');
      } else {
        content.classList.remove('displayBlockCK');
      }
    });
  }

  componentDidMount () {
    this.changeTextBa ();
    this.checkScrollFromTop ();
  }

  render () {
		let error = this.state.error;
		if (error) {
			return <div>Error: {error.message}</div>;
		} else {
      let txta0 = "Coach für";
      let txta1 = "führungskräfte und";
      let txta2 = "privatpersonen";
      txta0 = txta0.toUpperCase();
      txta1 = txta1.toUpperCase();
      txta2 = txta2.toUpperCase();
      let textc = "Wir bewegen und entwickeln uns. Weg von belastenden Situationen, hin zu guten Gefilden. Machen Schubladen zu und neue Sichtweisen auf. Erkennen Hürden und entwickeln Strategien, sie zu meistern. Probieren aus und lernen daraus. Definieren erstrebenswerte Ziele, richtige Wege und machbare Schritte. Wir sind du und ich.";
      textc = textc.toUpperCase();
			return (
        <div>
          <div id="ck"><img src="./c_k.webp" /></div>
          <div id="claudiaLogo" className="ce"><Lottie animationData={signatureAnimation1} loop={true} style={{marginTop: '-23%'}} /></div>
          <div className="ce lh25"><span className="font20px bookFont">{txta0} <br />{txta1} <br />{txta2}</span></div>
          <div className="spacerDesktop150px"></div>
          <div className="colorBox">
            <Row className="noRightPad">
              <Col>
                <div className="ce">
                  <video className="placeholderVideo" poster="./video-thumbnail-3240x1250.webp" controls>
                    <source src="./video_compressed.mp4" type="video/mp4" />
                  </video>
                </div>
              </Col>
              <Col className="noRightPad">
                <div className="maLe125">
                  <div className="le lh50 marginUp50pr">
                    <span className="bigFont bookFont">{this.state.textb0} <br />{this.state.textb1} <br />{this.state.textb2}</span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="spacerDesktop150px"></div>
          <div id="textCwrapper"><span className="bigFont2 bookFont">{textc}</span></div>
          <div className="ce"><Lottie animationData={signatureAnimation2} loop={true} style={{marginTop: '-10%', maxHeight: '800px'}} /></div>
          <div className="spacerDesktop100px"></div>
          <div className="whiteBox">
            <Row className="noRightPad">
              <Col>
                <div className="spacerDesktop100px"></div>
                <div className="male12pr">
                  <p><span className="bigFont bookFont">Wir sind weltbewegend. <br />Mit den richtigen Hebeln.</span></p>
                </div>
              </Col>
              <Col className="noRightPad"></Col>
            </Row>
            <hr />
            <Row className="noRightPad">
              <Col>
                <div className="spacerDesktop70px"></div>
                <div className="male12pr">
                  <p><span className="bigFont2 bookFont">Coaching</span></p>
                </div>
              </Col>
              <Col className="noRightPad">
                <div className="spacerDesktop70px"></div>
                <div className="male12pr mari12pr">
                  <p><span className="bookFont">Coaching ist ein strukturierter Entwicklungsweg und klar definierter Prozess. Wir bestimmen Standort und Ziel. Wir machen uns auf den Weg, erkennen dabei Hindernisse und erfassen Stärken. Wir analysieren, hinterfragen und evaluieren. Wir bewegen uns und kommen an.</span></p>
                  <p><span className="bookFont">Neues entsteht. Veränderung passiert. Dafür wenden wir erprobte Methoden an und du lernst neue Werkzeuge zu nutzen.</span></p>
                </div>
              </Col>
            </Row>
            <hr />
            <Row className="noRightPad">
              <Col>
                <div className="spacerDesktop70px"></div>
                <div className="male12pr">
                  <p><span className="bigFont2 bookFont">Psychologische <br />Beratung</span></p>
                </div>
              </Col>
              <Col className="noRightPad">
                <div className="spacerDesktop70px"></div>
                <div className="male12pr mari12pr">
                  <p><span className="bookFont">In der Psychologischen Beratung gewinnst du Klarheit. Ziele entstehen und neue Wege nehmen Gestalt an.</span></p>
                  <p><span className="bookFont">Ein offener und gleichzeitig geschützter Raum. Hier ist alles möglich: Erkenntnisse erfahren, Perspektiven entdecken, Entscheidungen treffen. Wir kommen ins Gespräch, erkennen Machbarkeiten und erarbeiten Strategien. Prüfen sie und verwerfen sie wieder. Finden neue Möglichkeiten und entwickeln uns weiter.</span></p>
                </div>
              </Col>
            </Row>
            <hr />
            <Row className="noRightPad">
              <Col>
                <div className="spacerDesktop70px"></div>
                <div className="male12pr">
                  <p><span className="bigFont2 bookFont">Trainings <br />und Workshops</span></p>
                </div>
              </Col>
              <Col className="noRightPad">
                <div className="spacerDesktop70px"></div>
                <div className="male12pr mari12pr">
                  <p><span className="bookFont">Für Gruppen sind Trainings und Workshops der ideale Rahmen. Hier ist individuelle Förderung und Entwicklung möglich und erlebbar.</span></p>
                  <p><span className="bookFont">In der Gruppe gehen wir in den Austausch. Wir erfahren neue Sichtweisen. Miteinander und voneinander. Wir erleben Transfer. Kreative Lösungen entstehen, Interaktion passiert.</span></p>
                </div>
              </Col>
            </Row>
          </div>
          <div className="colorBox colorBoxMod">
            <Row className="noRightPad">
              <Col>
                <div className="ce">
                  <Row>
                    <Col className="noRightPad">
                      <img src="./claudia01.webp" className="cpfp" />
                    </Col>
                    <Col className="noLeftPad">
                      <img src="./claudia02.webp" className="cpfp" />
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col className="noRightPad">
              </Col>
            </Row>
          </div>
          <Row className="noRightPad">
            <Col>
            </Col>
            <Col className="noRightPad">
              <div className="maLe125">
                <div className="le lh50 marginUp50pr">
                  <span className="bigFont bookFont">Keine <br />Veränderung <br />braucht Mut.</span>
                </div>
              </div>
            </Col>
          </Row>
          <div className="spacerDesktop100px"></div>
          <Row className="noRightPad">
            <Col>
              <div className="spacerDesktop70px"></div>
              <div className="male12pr">
                <p><span className="bigFont2 bookFont">Über mich</span></p>
              </div>
            </Col>
            <Col>
              <div className="spacerDesktop70px"></div>
              <div className="male12pr mari12pr">
                <p><span className="bookFont">Über 20 Jahre Erfahrung als Führungskraft im metallverarbeitenden Bereich, Beratung und Training im arbeitsmarktpolitischen Kontext sowie im Social Business, Projektbezogenes Coaching und organisatorische Veränderung in der Neuproduktentwicklung.</span></p>
              </div>
            </Col>
            <Col className="noRightPad">
              <div className="spacerDesktop70px"></div>
              <div className="male12pr mari12pr">
                <p><span className="bookFont">Dipl. Lebens- und Sozialberaterin <br />„Train the Trainer“ Lehrgang (Coaching You, Wien) <br />Paar, Familien- und Sexualberatung (Coaching You, Wien) <br />Resilienz-Coach (Resilienz Akademie Göttingen) <br />Hypnoseausbildung (Paracelsus Schule Lindau) <br />Supervisionslehrgang (Coaching You, Wien)</span></p>
              </div>
            </Col>
          </Row>
          <div className="spacerDesktop150px"></div>
          <div className="ce lh25"><span className="font20px bookFont">{txta0} <br />{txta1} <br />{txta2}</span></div>
          <div className="spacerDesktop150px"></div>
        </div>
      )
    }
  }
}